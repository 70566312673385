import * as R from "ramda";
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { LinkRenderer } from "../utils/utilities";

//components
import HubspotForm from "../components/non-panels/forms/HubSpotForm";
import { EbookLeadMagnetFormReplacementMessage } from "../components/non-panels/forms/replacementElements";
import SEO from "../components/seo";
import useSetPageData from "../utils/useSetPageData";
import contactStyle from "../components/non-panels/forms/Contact.module.scss";

import { dispatchWPDownloadLinkClick } from "../utils/GTMEventDispatchers";

//styles
import s from "./lp.module.scss";

const bookCover = R.view(R.lensPath(["bookCover", "childImageSharp", "fluid"]));

const mainImage = R.view(R.lensPath(["headerBackground", "publicURL"]));

const contentView = R.view(R.lensPath(["content", "edges", 0, "node"]));

const getHeaderBackground = R.view(
  R.lensPath(["headerBackground", "childImageSharp", "fluid"])
);

function stopFormAtFooter(setIsVisible) {
  const options = {
    root: null
  };

  return window => {
    const sentinel = window.document.querySelector(`.${s.sentinel}`);
    const observer = new window.IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    observer.observe(sentinel);

    return () => observer.disconnect();
  };
}

export default props => {
  const qData = useStaticQuery(graphql`
    query RHCTYQuery {
      logoRounded: file(relativePath: { eq: "logo-rounded-1536x361.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headerBackground: file(relativePath: { eq: "outside_the_box.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookCover: file(relativePath: { eq: "robotcareerhelper.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mainImage: file(relativePath: { eq: "robotcareerhelper.JPG" }) {
        publicURL
      }
      content: allMarkdownRemark(
        filter: {
          frontmatter: {
            title: {
              eq: "Why A Robot Will Be The Best Thing To Happen To Your Career"
            }
          }
        }
        limit: 1
      ) {
        edges {
          node {
            rawMarkdownBody
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const headerBackgoundFluid = getHeaderBackground(qData);
  const bookCoverFluid = bookCover(qData);
  const mainImageURL = mainImage(qData);
  const content = contentView(qData);

  const [isVisible, setIsVisible] = useState(null);

  useSetPageData(props.location);

  useEffect(() => {
    return R.ifElse(
      R.isNil,
      R.always(() => null),
      stopFormAtFooter(setIsVisible)
    )(window);
  }, []);

  return (
    <>
      <SEO
        {...{
          title: `Thank you, Why A Robot Will Be The Best Thing To Happen To Your Career E-book`,
          description:
            "Thank you, Read the eBook, Why a Robot Will Be the Best Thing to Happen to Your Career, and discover why the only thing robots take from our jobs is monotony."
        }}
        meta={
          mainImageURL
            ? [
                {
                  property: "og:image",
                  content: mainImageURL
                }
              ]
            : []
        }
      />
      <div className={`${s.wpContainer}`}>
        <BackgroundImage
          fluid={[
            "linear-gradient(rgba(43, 32, 112, 0.405), rgba(43, 32, 112, 0.99))",
            headerBackgoundFluid
          ]}
          Tag="section"
          className={`${s.wpHeader} ${s.thanksHeader}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className={s.headerTitleContent}>
                  <h4>Download the new E-book</h4>
                  <h1>
                    Why a Robot Will Be the Best Thing to Happen to Your Career
                  </h1>
                </div>
                <div className={s.headerTextContent}>
                  <p>
                    Read the eBook, Why a Robot Will Be the Best Thing to Happen
                    to Your Career, and discover why the only thing robots take
                    from our jobs is monotony.
                  </p>
                  <a
                    href="#form"
                    id="#button"
                    className={`btn btn-secondary btn-lg ${s.getButton}`}
                  >
                    Get the Ebook
                  </a>
                </div>
              </div>
              <div className="col-md"></div>
            </div>
          </div>
        </BackgroundImage>
        <article className="container">
          <div className={`row ${s.thanksHeight}`}>
            <div className={`${s.wpContent} ${s.thanksWidth}`}>
              <div className={`${contactStyle.whitepaperDownload} ${s.thanks}`}>
                <h2>Thank You!</h2>
                <p>Here is your E-book ready for download.</p>
                <a
                  href={
                    "/uploads/Ebook_Why_a_robot_will_be_the_best_thing_to_happen_to_your_career"
                  }
                  download
                  onClick={() => dispatchWPDownloadLinkClick()}
                >
                  DOWNLOAD
                </a>
              </div>
            </div>
          </div>
        </article>
        <i className={`${s.sentinel}`}></i>
      </div>
    </>
  );
};
